$('.first-slide').slick({
    arrows: false,
    dots: true,
    autoplay: true,
    autoplaySpeed: 6000,
    pauseOnHover: false,
    pauseOnFocus: false
})

$('.single-slider').slick({
    arrows: false,
    dots: true,
    pauseOnDotsHover: false,
    pauseOnHover: false,
    autoplay: true,
    autoplaySpeed: 4000,
    speed: 800
})

$('.gallery-slider').slick({
    arrows: true,
    dots: false,
    prevArrow: '<button class="btn dark icon icon-left-arrow prev"></button>',
    nextArrow: '<button class="btn dark icon icon-right-arrow next"></button>',
})

$('.portfolio-slider').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: '<button class="btn prev icon dark icon-left"></button>',
    nextArrow: '<button class="btn next icon dark icon-right"></button>',
    adaptiveHeight: true
})

$('.portfolio-rec-slider').slick({
    slidesToShow: 3,
    slidesToScroll: 3,
    prevArrow: '<button class="btn prev icon dark icon-left"></button>',
    nextArrow: '<button class="btn next icon dark icon-right"></button>',
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true
            }
        }
    ]
})

$('.product-rec-slider').slick({
    slidesToShow: 3,
    slidesToScroll: 3,
    prevArrow: '<button class="btn prev icon dark icon-left"></button>',
    nextArrow: '<button class="btn next icon dark icon-right"></button>',
    responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true
          }
        }
    ]
})