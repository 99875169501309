$('a[href^="#"]').on('click', function(event) {
    event.preventDefault()
    
    var sc = $(this).attr("href"),
        dn = $(sc).offset().top - $('header.site-header').height()
    $('html, body').animate({scrollTop: dn}, 700)
})

$('.scroll-to-next').click(function(){
    let elem = $(this)
    let section = elem.parents('section').next()

    if(section.length){
        let dn = section.offset().top - $('header.site-header').height()
        $('html, body').animate({scrollTop: dn}, 700)
        section
    }
})